import PropTypes from 'prop-types';
import React from 'react';
import {
	Button,
	Form,
	FormFeedback,
	Input,
	InputGroup,
	InputGroupAddon,
	Row,
	Col,
} from 'reactstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { MdChevronRight, MdLock, MdPerson } from 'react-icons/md';
import { BsFillPersonBadgeFill } from 'react-icons/bs';
import R from '../../lib/constants/R';
import SourceLink from '../Utils/SourceLink';

class AuthForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			senha: '',
			emailInvalid: false,
			passwordInvalid: false,
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(event) {
		event.preventDefault();
	
		const { senha, email } = this.state;
	
		if (!senha && !email) {
			this.setState({ emailInvalid: true, passwordInvalid: true });
			return;
		}
	
		if (!senha) {
			this.setState({ passwordInvalid: true });
			return;
		}
	
		if (!email) {
			this.setState({ emailInvalid: true });
			return;
		}
	
		const data = { email, senha };
		this.props.onSubmit(data); 
	}
	

	handleChange(event) {
		if (event.target.value === '') {
			this.setState({ [`${event.target.name}Invalid`]: true });
		} else {
			this.setState({ [`${event.target.name}Invalid`]: false });
		}

		this.setState({ [event.target.name]: event.target.value });
	}

	render() {
		const {
			showLogo,
			emailInvalidMsg,
			emailInputProps,
			passwordInvalidMsg,
			passwordInputProps,
			loading,
			onSubmit,
		} = this.props;

		const {
			email,
			senha,
			passwordInvalid,
			emailInvalid,
		} = this.state;

		const fraseOption = {
			marginTop: '25px',
			marginBottom: '25px',
		};

		return (
			<>
				<div className="banner"></div>
				<Row className="content">
					<Col xl={12} lg={12} md={12}>
						<div className="container">
							<img className="logo" src={R.images.decimo_logo} />
							<div className="screen">
								<div className="screen__content">
									<Form
										onSubmit={e =>
											this.handleSubmit(e, onSubmit)
										}>
										{showLogo && (
											<h5 className="login__title">Faça seu login</h5>
										)}

										<div className="input_container">

											<InputGroup>
												<InputGroupAddon
													addonType="prepend"
													className="authInput">
												</InputGroupAddon>
												<Input
													{...emailInputProps}
													invalid={emailInvalid}
													onChange={this.handleChange}
													value={email || ''}
												/>
												<FormFeedback className="ml-3">
													{emailInvalidMsg}
												</FormFeedback>
											</InputGroup>
										</div>

										<div className="input_container">

											<InputGroup>
												<InputGroupAddon addonType="prepend">

												</InputGroupAddon>
												<Input
													{...passwordInputProps}
													onChange={this.handleChange}
													invalid={passwordInvalid}
													color="red"
													value={senha || ''}
												/>

											</InputGroup>
										</div>
										<Button
											color="custom"
											className="btn-login"
											disabled={loading}
											block
											type="submit">
											{!loading ? (
												<div>
													{R.strings.login.login}{' '}
												</div>
											) : (
												<div>
													{R.strings.login.login}
													<ClipLoader
														size={20}
														color={
															R.colors
																.colorPrimary
														}
														loading={loading}
														className="float-right"
													/>
												</div>
											)}
										</Button>
									</Form>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</>
		);
	}
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
	authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]),
	showLogo: PropTypes.bool,
	emailInvalidMsg: PropTypes.string,
	emailInputProps: PropTypes.shape({}),
	passwordInvalidMsg: PropTypes.string,
	passwordInputProps: PropTypes.shape({}),
	onSubmit: PropTypes.func.isRequired,
	loading: PropTypes.bool,
};

AuthForm.defaultProps = {
	authState: 'LOGIN',
	showLogo: true,
	emailInvalidMsg: 'Preencha o campo usuário!',
	emailInputProps: {
		type: 'string',
		placeholder: R.strings.login.userPlaceholder,
		name: 'email',
	},
	passwordInvalidMsg: 'Preencha o campo senha!',
	passwordInputProps: {
		type: 'password',
		placeholder: R.strings.login.passwordPlaceholder,
		name: 'senha',
	},
	loading: false,
};

export default AuthForm;