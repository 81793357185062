import React, { createContext, useState, useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [usuario, setUser] = useState(null);

 if(usuario === null){
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
    }
  }

  useEffect(() => {
    if (usuario) {
      localStorage.setItem('user', JSON.stringify(usuario));
    } else {
      //localStorage.removeItem('user');
    }
  }, [usuario]);

  return (
    <UserContext.Provider value={{ usuario, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => React.useContext(UserContext);
