import React from 'react';
import { withRouter } from 'react-router-dom';
import '../../styles/table/table_custom.min.css';
import { ReactTabulator } from 'react-tabulator';
import { ColorRing } from 'react-loader-spinner';

import { getDataTable, getValorModal } from './TableData';

const REACT_APP_BASE_APP = process.env.REACT_APP_BASE_APP;
const months = [
	'Jan',
	'Fev',
	'Mar',
	'Abr',
	'Mai',
	'Jun',
	'Jul',
	'Ago',
	'Set',
	'Out',
	'Nov',
	'Dez',
];

const bmonths = [
	'Dez',
	'Jan',
	'Fev',
	'Mar',
	'Abr',
	'Mai',
	'Jun',
	'Jul',
	'Ago',
	'Set',
	'Out',
	'Nov',
];

const numMonths = [
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
];

const numMonthsB = [
	'12',
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
];

const ReturnMonths = [
	'janeiro',
	'fevereiro',
	'marco',
	'abril',
	'maio',
	'junho',
	'julho',
	'agosto',
	'setembro',
	'outubro',
	'novembro',
	'dezembro',
];

//lets
let date = new Date();
let currentMonth = date.getMonth();
let currentYear = date.getFullYear();
let results = [];
let Mresults = [];
let NumResults = [];

const startDate = new Date();
const endDate = new Date();
endDate.setDate(endDate.getDate() + 14);

const uniqueMonths = new Set();

for (let i = 0; i < 62; i++) {
	let nextMonth = (currentMonth + i) % 12;
	let nextYear = currentYear + Math.floor((currentMonth + i) / 12);

	if (nextMonth === 0) {
		nextMonth = 12;
		nextYear -= 1;
	}

	const key = `${nextYear}-${nextMonth}`;

	if (!uniqueMonths.has(key)) {
		results.push({
			month: months[nextMonth - 1],
			bmonth: bmonths[nextMonth - 1],
			year: nextYear.toString().slice(-2),
		});

		Mresults.push({
			month: ReturnMonths[nextMonth - 1],
			year: nextYear.toString().slice(-2),
		});

		NumResults.push({
			month: numMonths[nextMonth - 1],
			bmonth: numMonthsB[nextMonth - 1],
			year: nextYear.toString().padStart(4, '0'),
		});

		uniqueMonths.add(key);
	}
}

var editCheck = function (cell) {
	var data = cell.getRow().getData();

	return data.grupo === 'Previsão Contas a Pagar';
};

const moneyFormatter = function (cell, formatterParams) {
	const value = parseFloat(cell.getValue());
	const decimal = formatterParams.decimal || '.';
	const thousand = formatterParams.thousand || ',';
	const symbol = formatterParams.symbol || '';

	if (value == '' || value == '0' || value == undefined || value == null) {
		return '';
	}

	const stringValue = value.toFixed(2).replace('.', decimal);
	const parts = stringValue.split(decimal);
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousand);

	return symbol + parts.join(decimal);
};

const days = [];
for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
	d.setUTCHours(0, 0, 0, 0); // define a hora como 00:00:00 no fuso horário UTC
	days.push(
		d
			.toISOString()
			.slice(0, 19)
			.replace('T', ' '),
	);
}

const handleCellDblClick = (e, cell, props) => {
	console.log('e', e, 'cell', cell, 'props', props);

	const data = cell.getRow().getData();
	console.log('Dado: ' + JSON.stringify(data));

	if (data.grupo === 'Previsão Contas a Pagar') {
		console.log('grupo: ' + data.grupo);
	
		const queryParams = new URLSearchParams();

		queryParams.append('data', JSON.stringify(data));

		const url = '/tabela/aprovar?' + queryParams.toString();
		console.log('url: ' + url);

		// Abre a URL em uma nova guia ou janela
		window.open(url, '_blank');
	}
};

export const getTableData = (setStateFunction) => {
	const month = NumResults[0].month;
	const year = NumResults[0].year;

	getDataTable()
		.then(response => {
			setStateFunction({ datat: response });
		})
		.catch(error => {
			console.log(error);
		});

	getValorModal(month, year)
		.then(response => {
			setStateFunction({ Mdata: response });
		})
		.catch(error => {
			console.log(error);
		});

	Promise.all([getDataTable(), getValorModal(month, year)])
		.then(([dataTableResponse, valorModalResponse]) => {
			const dataTableWithTotalAndAprovado = dataTableResponse.map(
				dataTableItem => {
					const valorModalItem = valorModalResponse.find(
						valorModalItem =>
							valorModalItem.codobra ===
							dataTableItem.codobra &&
							valorModalItem.codorcam ===
							dataTableItem.codorcam,
					);
					return {
						...dataTableItem,
						total: valorModalItem ? valorModalItem.total : 0,
						aprovado: valorModalItem
							? valorModalItem.aprovacao_pgto
							: '',
					};
				},
			);
			console.log('dataTableWithTotalAndAprovado:', dataTableWithTotalAndAprovado);
			setStateFunction({ datat: dataTableWithTotalAndAprovado });
		})
		.catch(error => {
			console.log(error);
		});
}

const tableStyle = {
	background: '#f5f5f5',
	marginBottom: '5px',
	color: '#000000',
	fontSize: '14px',
	fontWeight: 'bold',
	border: '2px solid #ddd',
	borderRadius: '8px',
	width: '100%',
	tableLayout: 'fixed',
};

const rowStyle = {
	background: '#f5f5f5',
	color: '#000000',
	fontSize: '14px',
	textAlign: 'left',
	border: 'none',
};

class TableForm extends React.Component {
	constructor(props) {
		super(props);
		this.tableRef = React.createRef();
	}

	state = {
		data1: [],
		datat: [],
	};

	componentDidMount() {
		getTableData(this.setState.bind(this));
	}

	render() {
		const handleSave = (
			datat,
			props,
			valorCell,
			valorCodObra,
			valorCodOrcam,
			dtcolumn,
		) => {
			var parts = dtcolumn.split('/');
			var month = parseInt(parts[0]);
			var year = parseInt(parts[1]);

			var convertedDate = new Date(year, month - 1, 1);

			var dtData = convertedDate.toISOString().slice(0, 10);

			const saveData = {
				codobra: valorCodObra,
				codorcam: valorCodOrcam,
				valor: valorCell,
				dataprev: dtData,
			};

			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(saveData),
			};

			fetch(`${REACT_APP_BASE_APP}datatable`, requestOptions)
				.then(response => response.json())
				.then(response => {
					if (!response.retorno) {
						throw new Error('Erro ao salvar as informações na API');
					}
				})
				.catch(error => {
					console.log('invite 99: ' + JSON.stringify(saveData));
					console.log(
						'request 99: ' + JSON.stringify(requestOptions),
					);
					console.error(error);
					alert(`Erro ao salvar as informações: ${error.message}`);
				});
		};

		const columnData = () => [
			{
				title: 'Descrição',
				field: 'nmobra',
				width: 150,
				frozen: true,
				hozAlign: 'left',
				headerFilter: 'input',
			},
			{
				title: 'Unidade',
				field: 'aunidade',
				width: 200,
				frozen: true,
				hozAlign: 'left',
				headerFilter: 'input',
			},
			{
				title: 'Status',
				field: 'vendido',
				width: 150,
				frozen: true,
				hozAlign: 'left',
				headerFilter: 'input',
			},
			{
				title: 'Nome do Cliente',
				field: 'nmcliente',
				width: 150,
				frozen: true,
				hozAlign: 'left',
				headerFilter: 'input',
			},
			...results.map((result, index) => ({
				title: results[index].month + '/' + results[index].year,
				field: NumResults[index].month + '/' + NumResults[index].year,
				hozAlign: 'right',
				width: 150,
				formatter: function (cell, formatterParams) {
					var total = cell.getRow().getData().total;
					var ap = cell.getRow().getData().aprovado;
					var gr = cell.getRow().getData().grupo;
					const valorCell = cell.getValue();

					let formattedValue = valorCell;

					if (
						(valorCell > 0 && gr === 'Previsão Contas a Pagar') ||
						(cell.getValue() > total &&
							gr === 'Previsão Contas a Pagar')
					) {
						cell.getElement().style.color = '#108A20';
						formattedValue = moneyFormatter(cell, formatterParams);
					} else if (
						(valorCell < 0 && gr === 'Previsão Contas a Pagar') ||
						(cell.getValue() < total &&
							gr === 'Previsão Contas a Pagar')
					) {
						cell.getElement().style.color = '#8A1010';
						formattedValue = moneyFormatter(cell, formatterParams);
					}

					if (gr !== 'Previsão Contas a Pagar') {
						if (valorCell >= 0) {
							cell.getElement().style.color = '#108A20';
							formattedValue = moneyFormatter(
								cell,
								formatterParams,
							);
						} else if (valorCell < 0) {
							cell.getElement().style.color = '#8A1010';
							formattedValue = moneyFormatter(
								cell,
								formatterParams,
							);
						} else if (valorCell === 'undefined') {
							cell.getElement().style.color = 'blue';
							formattedValue = moneyFormatter(
								cell,
								formatterParams,
							);
						}
					}
					return formattedValue;
				},
				formatterParams: {
					decimal: ',',
					thousand: '.',
					symbol: 'R$ ',
				},
				bottomCalc: 'sum',
				bottomCalcParams: { precision: 2 },
				bottomCalcFormatter: function (cell, formatterParams) {
					const valorCell = cell.getValue();

					let formattedValue = valorCell;
					if (valorCell >= 0) {
						cell.getElement().style.color = '#108A20';
						formattedValue = moneyFormatter(cell, formatterParams);
					} else if (valorCell < 0) {
						cell.getElement().style.color = '#8A1010';
						formattedValue = moneyFormatter(cell, formatterParams);
					} else if (valorCell === 'undefined') {
						cell.getElement().style.color = 'blue';
						formattedValue = moneyFormatter(cell, formatterParams);
					}
					return formattedValue;
				},
				bottomCalcFormatterParams: {
					decimal: ',',
					thousand: '.',
					symbol: 'R$ ',
				},
				editor: 'input',
				editable: index === 0 ? false : editCheck,
				cellDblClick: (e, cell) =>
					handleCellDblClick(e, cell, this.props),
			})),
		];

		const optionsTable = {
			height: '110%',
			persistentLayout: true,
			groupBy: 'grupo',
			reactiveData: true,
			groupStartOpen: false,
			columnCalcs: 'group',
			groupClosedShowCalcs: true,
			groupHeader: function (value, count, data, group) {
				return (
					"<span style='color:#004992;'>" +
					value +
					' </span>' +
					"<span style='color:#004992; margin-left:10px;'>( " +
					count +
					' items )</span>'
				);
			},
		};

		return (
			<div className="parent">
				<div className="div1">
					{/* tabela Dados */}
					{this.state.datat.length > 0 ? (
						<ReactTabulator
							style={tableStyle}
							rowStyle={rowStyle}
							columns={columnData()}
							data={this.state.datat}
							options={optionsTable}
							ref={this.tableRef}
							events={{
								cellEdited: cell => {
									const valorCell = cell.getValue();
									const rowData = cell.getRow().getData();
									const valorCodObra = rowData['codobra'];
									const valorCodOrcam = rowData['codorcam'];
									const dtcolumn = cell.getColumn().getField();

									handleSave(
										this.state.datat,
										this.props,
										valorCell,
										valorCodObra,
										valorCodOrcam,
										dtcolumn,
									);
								},
							}}
						/>
					) : (
					<div className='loadingDiv'> 
							<ColorRing
							visible={true}
							height="80"
							width="80"
							ariaLabel="color-ring-loading"
							wrapperStyle={{}}
							wrapperClass="color-ring-wrapper"
							colors={['#004992', '#004992', '#004992', '#004992', '#004992']}
						/>
						</div>
					)}

				</div>
			</div>
		);
	}
}

export default withRouter(TableForm);
