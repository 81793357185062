import React from 'react';
import classNames from 'classnames';
import { Card, CardBody, CardSubtitle, CardText, CardTitle } from 'reactstrap';
import PropTypes from '../../../lib/utils/propTypes';
import Avatar from '../Avatar';

const UserCard = ({
	avatar,
	avatarSize,
	title,
	subtitle,
	text,
	children,
	className,
	...restProps
}) => {
	const classes = classNames('bg-gradient-theme', className);

	return (
		<Card inverse className={classes} {...restProps}>
			<CardBody className="d-flex justify-content-center align-items-center flex-column">
				<Avatar src={avatar} size={avatarSize} className="mb-2" />
				<CardTitle>{title}</CardTitle>
				<CardSubtitle>{subtitle}</CardSubtitle>
				<CardText>
					<small>{text}</small>
				</CardText>
			</CardBody>
			{children}
		</Card>
	);
};

UserCard.propTypes = {
	avatar: PropTypes.string.isRequired,
	avatarSize: PropTypes.number,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	text: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};

UserCard.defaultProps = {
	avatarSize: 80,
	subtitle: '',
	text: '',
	className: '',
};

export default UserCard;
