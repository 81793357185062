import React from 'react';
import { LoadingContent, Page } from '../../components/Utils/Page';
import ConfirmationPage from './TableConfirmation';

class AprovePage extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Page
				className="tablePg"
				title="Aprovação de Pagamentos"
				breadcrumbs={[{ name: 'Tabela', active: true }]}>
				<LoadingContent loading={false}>
					<ConfirmationPage />
				</LoadingContent>
			</Page>
		);
	}
}

export default AprovePage;
