import api from './api';

export const get = query => {
	return api.get(`/usuario${query}`);
};

export const getCollaborator = query => {
	return api.get(`/usuario/collaborator${query}`);
};

export const getUser = id => {
	return api.get(`/usuario?id=${id}`);
};

export const getUserPermission = (usuarioId) => {
  
    return api.get(`/modulo?usuario_id=${usuarioId}`);
};

export const activeDesactiveUser = (id, condition) => {
	
	return api.put(`/usuario/${id}`, condition);
};

export const updateUserPermission = (data) => {
	return api.post(`/modulo-usuario`, data);
};

export const addUser = data => {
	return api.post(`/usuario`, data);
};

export const changePassword = (data, id) => {
	return api.put(`/usuario/${id}`, data);
};

export const editUser = (data, id) => {
	return api.put(`/usuario/${id}`, data);
};
