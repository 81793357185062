import React from 'react';
import { withRouter } from 'react-router-dom';
import '../../styles/table/table_custom.min.css';
import { ReactTabulator } from 'react-tabulator';
import { ColorRing } from 'react-loader-spinner';
//import { CommentaryModal } from '../../components/Utils/CommentaryModal';

import { getDataTable, getValorModal } from './TableData';
import { parse } from 'date-fns';
import { func } from 'prop-types';

const REACT_APP_BASE_APP = process.env.REACT_APP_BASE_APP;
const months = [
	'Jan',
	'Fev',
	'Mar',
	'Abr',
	'Mai',
	'Jun',
	'Jul',
	'Ago',
	'Set',
	'Out',
	'Nov',
	'Dez',
];

const bmonths = [
	'Dez',
	'Jan',
	'Fev',
	'Mar',
	'Abr',
	'Mai',
	'Jun',
	'Jul',
	'Ago',
	'Set',
	'Out',
	'Nov',
];

const numMonths = [
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
];

const numMonthsB = [
	'12',
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
];

const ReturnMonths = [
	'janeiro',
	'fevereiro',
	'marco',
	'abril',
	'maio',
	'junho',
	'julho',
	'agosto',
	'setembro',
	'outubro',
	'novembro',
	'dezembro',
];

//lets
let date = new Date();
let currentMonth = date.getMonth();
let currentYear = date.getFullYear();
let results = [];
let Mresults = [];
let NumResults = [];

const startDate = new Date();
const endDate = new Date();
endDate.setDate(endDate.getDate() + 14);

const uniqueMonths = new Set();

for (let i = 0; i < 62; i++) {
	let nextMonth = (currentMonth + i) % 12;
	let nextYear = currentYear + Math.floor((currentMonth + i) / 12);

	if (nextMonth === 0) {
		nextMonth = 12;
		nextYear -= 1;
	}

	const key = `${nextYear}-${nextMonth}`;

	if (!uniqueMonths.has(key)) {
		results.push({
			month: months[nextMonth - 1],
			bmonth: bmonths[nextMonth - 1],
			year: nextYear.toString().slice(-2),
		});

		Mresults.push({
			month: ReturnMonths[nextMonth - 1],
			year: nextYear.toString().slice(-2),
		});

		NumResults.push({
			month: numMonths[nextMonth - 1],
			bmonth: numMonthsB[nextMonth - 1],
			year: nextYear.toString().padStart(4, '0'),
		});

		uniqueMonths.add(key);
	}
}

var editCheck = function (cell) {
	var data = cell.getRow().getData();

	return data.grupo === 'Previsão Contas a Pagar';
};

const moneyFormatter = function (cell, formatterParams) {
	const value = parseFloat(cell.getValue());
	const decimal = formatterParams.decimal || '.';
	const thousand = formatterParams.thousand || ',';
	const symbol = formatterParams.symbol || '';

	if (value == '' || value == '0' || value == undefined || value == null) {
		return '';
	}

	const stringValue = value.toFixed(2).replace('.', decimal);
	const parts = stringValue.split(decimal);
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousand);

	return symbol + parts.join(decimal);
};

const totalFormatter = function (total) {
	const decimal = ','; // Separador decimal brasileiro
	const thousand = '.'; // Separador de milhar brasileiro
	const symbol = 'R$ '; // Símbolo monetário

	if (total === '' || total === '0' || total === undefined || total === null || isNaN(total)) {
		return ''; // Retorna vazio para valores inválidos
	}

	// Formatar o número com duas casas decimais
	const stringValue = total.toFixed(2).replace('.', decimal);
	const parts = stringValue.split(decimal);
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousand);

	return symbol + parts.join(decimal); // Retorna o valor formatado com "R$"
};

const days = [];
for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
	d.setUTCHours(0, 0, 0, 0); // define a hora como 00:00:00 no fuso horário UTC
	days.push(
		d
			.toISOString()
			.slice(0, 19)
			.replace('T', ' '),
	);
}

const handleCellDblClick = (e, cell, props) => {
	console.log('e', e, 'cell', cell, 'props', props);

	const data = cell.getRow().getData();
	console.log('Dado: ' + JSON.stringify(data));

	if (data.grupo === 'Previsão Contas a Pagar') {
		console.log('grupo: ' + data.grupo);

		const queryParams = new URLSearchParams();

		queryParams.append('data', JSON.stringify(data));

		const url = '/tabela/aprovar?' + queryParams.toString();
		console.log('url: ' + url);

		// Abre a URL em uma nova guia ou janela
		window.open(url, '_blank');
	}
};

export const getTableData = (setStateFunction) => {
	const month = NumResults[0].month;
	const year = NumResults[0].year;

	getDataTable()
		.then(response => {
			setStateFunction({ datat: response });
		})
		.catch(error => {
			console.log(error);
		});

	getValorModal(month, year)
		.then(response => {
			setStateFunction({ Mdata: response });
		})
		.catch(error => {
			console.log(error);
		});

	Promise.all([getDataTable(), getValorModal(month, year)])
		.then(([dataTableResponse, valorModalResponse]) => {
			const dataTableWithTotalAndAprovado = dataTableResponse.map(
				dataTableItem => {
					const valorModalItem = valorModalResponse.find(
						valorModalItem =>
							valorModalItem.codobra ===
							dataTableItem.codobra &&
							valorModalItem.codorcam ===
							dataTableItem.codorcam,
					);
					return {
						...dataTableItem,
						total: valorModalItem ? valorModalItem.total : 0,
						aprovado: valorModalItem
							? valorModalItem.aprovacao_pgto
							: '',
					};
				},
			);
			setStateFunction({ datat: dataTableWithTotalAndAprovado });
		})
		.catch(error => {
			console.log(error);
		});
}

const uniqueYears = new Set();

results.forEach(result => {
	uniqueYears.add(result.year);
});

const uniqueYearsArray = Array.from(uniqueYears);


var headerMenu = function () {
	var menu = [];
	var columns = this.getColumns();

	for (let column of columns) {

		//create checkbox element using font awesome icons
		let icon = document.createElement("i");
		icon.classList.add("fas");
		icon.classList.add(column.isVisible() ? "fa-check-square" : "fa-square");

		//build label
		let label = document.createElement("span");
		let title = document.createElement("span");

		title.textContent = " " + column.getDefinition().title;

		label.appendChild(icon);
		label.appendChild(title);

		//create menu item
		menu.push({
			label: label,
			action: function (e) {
				//prevent menu closing
				e.stopPropagation();

				//toggle current column visibility
				column.toggle();

				//change menu item icon
				if (column.isVisible()) {
					icon.classList.remove("fa-square");
					icon.classList.add("fa-check-square");
				} else {
					icon.classList.remove("fa-check-square");
					icon.classList.add("fa-square");
				}
			}
		});
	}

	return menu;
};

var rowMenu = [
	{
		label: `
            <div style="
                display: flex; 
                align-items: center; 
                background-color: #fff; 
                padding: 2px; 
                border-radius: 4px; 
                transition: background-color 0.3s ease;">
            
            <i class="fas fa-user" style="margin-right: 8px; color: #4caf50;"></i>
            <span style="font-weight: bold; color: #333;">Adicionar Comentário</span>
        </div>
    `,
		action: (e, row) => {

			console.log(this);  // Verifique o contexto de 'this'
			// this.openModal();
		}
	},
];


var style = document.createElement('style');
style.innerHTML = `
    .row-menu-item:hover {
        background-color: #007bff;
        cursor: pointer; 
    }
`;
document.head.appendChild(style);




const tableStyle = {
	background: '#f5f5f5',
	marginBottom: '5px',
	color: '#000000',
	fontSize: '14px',
	fontWeight: 'bold',
	border: '2px solid #ddd',
	borderRadius: '8px',
	width: '100%',
	tableLayout: 'fixed',
};

const rowStyle = {
	background: '#f5f5f5',
	color: '#000000',
	fontSize: '14px',
	textAlign: 'left',
	border: 'none',
};

class TableForm extends React.Component {
	constructor(props) {
		super(props);
		this.tableRef = React.createRef();
	}

	state = {
		data1: [],
		datat: [],
		isModalOpen: false,
	};

	componentDidMount() {
		getTableData(this.setState.bind(this));
	}

	openModal = () => {
		this.setState({ isModalOpen: true });
		console.log('isModalOpen: ' + this.state.isModalOpen);
	};


	closeModal = () => {
		this.setState({ isModalOpen: false });
	};


	render() {
		const { isModalOpen } = this.state;

		var rowMenu = [
			{
				label: `
					<div style="
						display: flex; 
						align-items: center; 
						background-color: #fff; 
						padding: 2px; 
						border-radius: 4px; 
						transition: background-color 0.3s ease;">
					
					<i class="fas fa-user" style="margin-right: 8px; color: #4caf50;"></i>
					<span style="font-weight: bold; color: #333;">Adicionar Comentário</span>
				</div>
			`,
				action: (e, row) => {
					// Identificar a célula exata clicada
					const clickedCell = e.target.closest('td'); // "closest" encontra o elemento 'td' mais próximo

					if (clickedCell) {
						// Agora você tem a célula exata em 'clickedCell'
						console.log('Célula clicada:', clickedCell);

						// Aqui você pode acessar a célula, como o valor da célula ou seu índice
						const cellValue = clickedCell.innerText; // Valor da célula
						console.log('Valor da célula:', cellValue);
					}

					this.openModal();
				}
			},
		];



		const handleSave = (
			datat,
			props,
			valorCell,
			valorCodObra,
			valorCodOrcam,
			dtcolumn,
		) => {
			var parts = dtcolumn.split('/');
			var month = parseInt(parts[0]);
			var year = parseInt(parts[1]);

			var convertedDate = new Date(year, month - 1, 1);

			var dtData = convertedDate.toISOString().slice(0, 10);

			const saveData = {
				codobra: valorCodObra,
				codorcam: valorCodOrcam,
				valor: valorCell,
				dataprev: dtData,
			};

			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(saveData),
			};

			fetch(`${REACT_APP_BASE_APP}datatable`, requestOptions)
				.then(response => response.json())
				.then(response => {
					if (!response.retorno) {
						throw new Error('Erro ao salvar as informações na API');
					}
				})
				.catch(error => {
					console.log('invite 99: ' + JSON.stringify(saveData));
					console.log(
						'request 99: ' + JSON.stringify(requestOptions),
					);
					console.error(error);
					alert(`Erro ao salvar as informações: ${error.message}`);
				});
		};

		const columnData = () => [
			{
				title: 'Descrição',
				field: 'nmobra',
				width: 150,
				frozen: true,
				hozAlign: 'left',
				headerFilter: 'input',
			},
			{
				title: 'Unidade',
				field: 'aunidade',
				width: 200,
				frozen: true,
				hozAlign: 'left',
				headerFilter: 'input',
			},
			{
				title: 'Status',
				field: 'vendido',
				width: 150,
				frozen: true,
				hozAlign: 'left',
				headerFilter: 'input',
			},
			{
				title: 'Nome do Cliente',
				field: 'nmcliente',
				width: 150,
				frozen: true,
				hozAlign: 'left',
				headerFilter: 'input',
			},
			...results.map((result, index) => ({
				title: results[index].month + '/' + results[index].year,
				field: NumResults[index].month + '/' + NumResults[index].year,
				hozAlign: 'right',
				width: 150,
				formatter: function (cell, formatterParams) {
					var total = cell.getRow().getData().total;
					var ap = cell.getRow().getData().aprovado;
					var gr = cell.getRow().getData().grupo;
					var data = cell.getRow().getData();
					const valorCell = cell.getValue();

					const currentDate = new Date();
					const previousMonthDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
					const previousMonth = ("0" + (previousMonthDate.getMonth() + 1)).slice(-2); // Mês no formato MM
					const previousYear = previousMonthDate.getFullYear().toString(); // Ano no formato YY
					const previousMonthFormatted = `${previousMonth}/${previousYear}`;
				
					let formattedValue = valorCell;
				
					// Verificando se o grupo é "Contas a Receber" e se o mês anterior está no objeto 'data'
					

					if (
						(valorCell > 0 && gr === 'Previsão Contas a Pagar') ||
						(valorCell > total && gr === 'Previsão Contas a Pagar')
					) {
						cell.getElement().style.color = '#108A20';
						formattedValue = moneyFormatter(cell, formatterParams);
					} else if (
						(valorCell < 0 && gr === 'Previsão Contas a Pagar') ||
						(valorCell < total && gr === 'Previsão Contas a Pagar')
					) {
						cell.getElement().style.color = '#8A1010';
						formattedValue = moneyFormatter(cell, formatterParams);
					}

					if (gr !== 'Previsão Contas a Pagar') {
						if (valorCell >= 0) {
							cell.getElement().style.color = '#108A20';
							formattedValue = moneyFormatter(cell, formatterParams);
						} else if (valorCell < 0) {
							cell.getElement().style.color = '#8A1010';
							formattedValue = moneyFormatter(cell, formatterParams);
						} else if (valorCell === 'undefined') {
							cell.getElement().style.color = 'blue';
							formattedValue = moneyFormatter(cell, formatterParams);
						}
					}

					if (gr === 'Contas a Receber') {
						
						const dataObject = data; 
						 
						if (dataObject.hasOwnProperty(previousMonthFormatted)) {
							
							if (cell.getColumn().getField() === previousMonthFormatted && valorCell != null && valorCell != undefined) {
								
								cell.getElement().style.color = '#8A1010';
								
								
								formattedValue = moneyFormatter(cell, formatterParams);
							}
						}
					}
					

					return formattedValue;
				},
				headerMenu: headerMenu,
				formatterParams: {
					decimal: ',',
					thousand: '.',
					symbol: 'R$ ',
				},
				bottomCalc: 'sum',
				bottomCalcParams: { precision: 2 },
				bottomCalcFormatter: function (cell, formatterParams) {
					const valorCell = cell.getValue();

					let formattedValue = valorCell;
					if (valorCell >= 0) {
						cell.getElement().style.color = '#108A20';
						formattedValue = moneyFormatter(cell, formatterParams);
					} else if (valorCell < 0) {
						cell.getElement().style.color = '#8A1010';
						formattedValue = moneyFormatter(cell, formatterParams);
					} else if (valorCell === 'undefined') {
						cell.getElement().style.color = 'blue';
						formattedValue = moneyFormatter(cell, formatterParams);
					}
					return formattedValue;
				},
				bottomCalcFormatterParams: {
					decimal: ',',
					thousand: '.',
					symbol: 'R$ ',
				},
				editor: 'input',
				editable: index === 0 ? false : editCheck,
				cellDblClick: (e, cell) =>
					handleCellDblClick(e, cell, this.props),
			})),

			...uniqueYearsArray.map((year) => ({
				title: `Total de 20${year}`,
				field: `Total de 20${year}`,
				hozAlign: 'right',
				width: 150,
				formatter: function (cell) {
					const rowData = cell.getRow().getData();
					const grupo = rowData.grupo; // Verifica o grupo da linha
					let total = 0;
					let totalValue = total;

					rowData[`total_${year}`] = total;

					// Soma os valores apenas se o grupo for "Previsão Contas a Pagar"
					if (grupo === 'Previsão Contas a Pagar') {
						Object.keys(rowData).forEach((key) => {
							if (key.endsWith(`${year}`)) {
								const value = parseFloat(rowData[key]);
								if (!isNaN(value)) {
									total += value;
								}
							}
						});
					}

					if (total === 0) {
						return ""; // Retorna célula vazia se não houver total
					}

					if (total === 0) {
						return ""; // Retorna "0" se o total for zero
					} else if (total >= 0) {
						cell.getElement().style.color = '#108A20';
						totalValue = totalFormatter(total);
					} else if (total < 0) {
						cell.getElement().style.color = '#8A1010';
						totalValue = totalFormatter(total);
					} else if (total === 'undefined') {
						cell.getElement().style.color = 'blue';
						totalValue = totalFormatter(total);
					}
					return totalValue;

				},
				formatterParams: {
					decimal: ',',
					thousand: '.',
					symbol: 'R$ ',
				},
				bottomCalc: function (values, data) {
					try {
						let total = 0;
						const yearGet = year


						data.forEach((row) => {
							const grupo = row.grupo;

							if (grupo === "Previsão Contas a Pagar") {
								Object.keys(row).forEach((key) => {
									if (key.endsWith(yearGet)) {
										const value = parseFloat(row[key]);
										if (!isNaN(value)) {
											total += value;
										}
									}
								});
							}
						});

						return total;
					} catch (error) {
						console.log('error', error);
					}
				},


				bottomCalcParams: { precision: 2 },
				bottomCalcFormatter: function (cell, formatterParams) {
					const valorCell = cell.getValue();

					let formattedValue = valorCell;
					if (valorCell >= 0) {
						cell.getElement().style.color = '#108A20';
						formattedValue = moneyFormatter(cell, formatterParams);
					} else if (valorCell < 0) {
						cell.getElement().style.color = '#8A1010';
						formattedValue = moneyFormatter(cell, formatterParams);
					} else if (valorCell === 'undefined') {
						cell.getElement().style.color = 'blue';
						formattedValue = moneyFormatter(cell, formatterParams);
					}
					return formattedValue;
				},

				bottomCalcFormatterParams: {
					decimal: ',',
					thousand: '.',
					symbol: 'R$ ',
				},
			}))
			, {
				title: `Total`,
				field: `Total`,
				hozAlign: 'right',
				width: 150,
				formatter: function (cell) {
					const rowData = cell.getRow().getData();
					console.log('rowData', rowData);
					const grupo = rowData.grupo;
					let total = 0;

					// Soma os valores apenas se o grupo for "Previsão Contas a Pagar"
					if (grupo === 'Previsão Contas a Pagar') {
						Object.keys(rowData).forEach((key) => {
							// Verifica se a key está no formato mm/yyyy
							if (/^\d{2}\/\d{4}$/.test(key)) {
								const value = parseFloat(rowData[key]);
								if (!isNaN(value)) {
									total += value;
								}
							}
						});
					}


					let totalValue = total;

					if (total === 0) {
						return "";
					} else if (total >= 0) {
						cell.getElement().style.color = '#108A20';
						totalValue = totalFormatter(total);
					} else if (total < 0) {
						cell.getElement().style.color = '#8A1010';
						totalValue = totalFormatter(total);
					} else if (total === 'undefined') {
						cell.getElement().style.color = 'blue';
						totalValue = totalFormatter(total);
					}
					return totalValue;
				},
				formatterParams: {
					decimal: ',',
					thousand: '.',
					symbol: 'R$ ',
				},
				bottomCalc: function (values, data) {
					let total = 0;
				
					data.forEach((row) => {
						const grupo = row.grupo;
				
						if (grupo === 'Previsão Contas a Pagar') {
							Object.keys(row).forEach((key) => {
								// Verifica se a key está no formato mm/yyyy
								if (/^\d{2}\/\d{4}$/.test(key)) {
									const value = parseFloat(row[key]);
									if (!isNaN(value)) {
										total += value;
									}
								}
							});
						}
					});
				
					return total;
				},
				
				bottomCalcParams: { precision: 2 },
				bottomCalcFormatter: function (cell, formatterParams) {
					const valorCell = cell.getValue();

					let formattedValue = valorCell;
					if (valorCell >= 0) {
						cell.getElement().style.color = '#108A20';
						formattedValue = moneyFormatter(cell, formatterParams);
					} else if (valorCell < 0) {
						cell.getElement().style.color = '#8A1010';
						formattedValue = moneyFormatter(cell, formatterParams);
					} else if (valorCell === 'undefined') {
						cell.getElement().style.color = 'blue';
						formattedValue = moneyFormatter(cell, formatterParams);
					}
					return formattedValue;
				},
				bottomCalcFormatterParams: {
					decimal: ',',
					thousand: '.',
					symbol: 'R$ ',
				},
			}



		];


		const optionsTable = {
			height: '110%',
			rowContextMenu: rowMenu,
			// persistentLayout: true,
			groupBy: 'grupo',
			reactiveData: true,
			groupStartOpen: false,
			columnCalcs: 'group',
			groupClosedShowCalcs: true,
			groupHeader: function (value, count, data, group) {
				return (
					"<span style='color:#004992;'>" +
					value +
					' </span>' +
					"<span style='color:#004992; margin-left:10px;'>( " +
					count +
					' items )</span>'
				);
			},
		};



		return (
			<div className="parent">
				<div className="div1">
					
					{/* tabela Dados */}
					{this.state.datat.length > 0 ? (
						<ReactTabulator

							style={tableStyle}
							rowStyle={rowStyle}
							columns={columnData()}
							data={this.state.datat}
							options={optionsTable}
							ref={this.tableRef}
							events={{
								cellEdited: cell => {
									const valorCell = cell.getValue();
									const rowData = cell.getRow().getData();
									const valorCodObra = rowData['codobra'];
									const valorCodOrcam = rowData['codorcam'];
									const dtcolumn = cell.getColumn().getField();

									handleSave(
										this.state.datat,
										this.props,
										valorCell,
										valorCodObra,
										valorCodOrcam,
										dtcolumn,
									);
								},
							}}
						/>
					) : (
						<div className='loadingDiv'>
							<ColorRing
								visible={true}
								height="80"
								width="80"
								ariaLabel="color-ring-loading"
								wrapperStyle={{}}
								wrapperClass="color-ring-wrapper"
								colors={['#004992', '#004992', '#004992', '#004992', '#004992']}
							/>
						</div>
					)}

				</div>
			</div>
		);
	}
}

export default withRouter(TableForm);
