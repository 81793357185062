import React from 'react';
import { Collapse, NavItem, NavLink as BSNavLink } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import PropTypes from '../../../lib/utils/propTypes';
import bn from '../../../lib/utils/bemnames';
import * as r from 'react-icons/md'; // Importa todos os ícones do 'react-icons/md'

const bem = bn.create('sidebar');

// Função para buscar o ícone no objeto `r` baseado no nome
const findIcon = (iconName) => {
  return r[iconName] || null;
};

const SidebarPage = ({
  isOpen,
  pageContents,
  namePages,
  IconPage,
  click,
  ...restProps
}) => {
  const IconComponent = findIcon(IconPage);

  return (
    <div {...restProps} style={{ display: 'contents' }}>
      <NavItem className='custom-nav-item' onClick={click(namePages)}>
        <BSNavLink className={bem.e('nav-item-collapse')}>
          <div className="d-flex">
            {IconComponent && <IconComponent className={bem.e('nav-item-icon')} />}
            <span className="nav-name">{namePages}</span>
          </div>
        </BSNavLink>
      </NavItem>
      <Collapse isOpen={isOpen}>
        {pageContents.map(({ url, nome, exact, icone }, index) => {
          const SubIcon = findIcon(icone);

		  return (
            <NavItem key={nome} className={bem.e('nav-item nav-sub-item')} style={{ display: 'flex', width: '100%' }}>
              <BSNavLink
                id={`navItem-${nome}-${index}`}
                tag={NavLink}
                to={url}
                activeClassName="active"
                exact={exact}
                style={{ display: 'flex', alignItems: 'center', width: '165px', padding: '10px' }}
              >
                {SubIcon && <SubIcon className={bem.e('nav-item-icon icon-small')} />}
                <span style={{ flex: 1, textAlign: 'center' }}>{nome}</span>
              </BSNavLink>
            </NavItem>
          );
        })}
      </Collapse>
    </div>
  );
};

SidebarPage.propTypes = {
  namePages: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  IconPage: PropTypes.string.isRequired,
  pageContents: PropTypes.arrayOf(PropTypes.object).isRequired,
  click: PropTypes.func.isRequired,
};

SidebarPage.defaultProps = {
  isOpen: false,
};

export default SidebarPage;
