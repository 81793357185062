import React from 'react';
import { connect } from 'react-redux';
import Form from '../../components/User/UserRegisterForm';
import { navigateBack } from '../../lib/utils/navigation';
import {
    groupsActions,
    notificationActions,
    usersActions,
    genericsActions,
} from '../../store/actions';
import R from '../../lib/constants/R';
import { LoadingContent, Page } from '../../components/Utils/Page';
import PropTypes from '../../lib/utils/propTypes';
import api from 'api/appApi/api';

class UserRegisterPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null,
            user: null, // Inicializa como null
            loadingUser: true,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        const { onGetRepresentatives, match } = this.props;

        await onGetRepresentatives();

        const { id } = match.params;

        this.setState({ id });

        if (id) {
            this.loadUser(id);
        } else {
            this.setState({ loadingUser: false });
        }
    }

    async loadUser(id) {
        try {
            const response = await api.get(`/usuario?id=${id}`);
            const usuario = response.data;
            this.setState({ user: usuario[0] || {}, loadingUser: false });
        } catch (error) {
            console.error('Erro ao buscar usuário:', error);
            this.setState({ loadingUser: false });
        }
    }

    onSubmit = (data) => {
        // const { onEditUser, onAddNotification, onAddUser } = this.props;
        // const { id } = this.state;
        // const values = data;

        // if (id) {
        //     if (data.senha === '') {
        //         delete values.senha;
        //     } else if (data.senha !== data.password) {
        //         onAddNotification(R.strings.error.passwordError, 'error');
        //         return;
        //     }
        //     onEditUser(values, id);
        // } 
		// else {
        //     if (data.senha !== data.password) {
        //         onAddNotification(R.strings.error.passwordError, 'error');
        //         return;
        //     }
        //     onAddUser(data);
        // }
    };

    render() {
        const { id, user, loadingUser } = this.state;
        const { loading, companies, groups, representatives } = this.props;

        if (loadingUser) {
            return <LoadingContent loading={true} />;
        }

        return (
            <Page
                className="user-register"
                title={id ? 'Editar' : 'Adicionar'}
                parentBreadcrumbs="Usuários"
                pathParent="/usuarios"
                breadcrumbs={[
                    {
                        name: id ? 'Editar usuário' : 'Adicionar usuário',
                        active: true,
                    },
                ]}>
                <LoadingContent loading={loading}>
                    <Form
                        user={user || {}}
                        companiesList={companies || []}
                        groups={groups || []}
                        edit={!!id}
                        representatives={representatives}
                        onSubmit={data => this.onSubmit(data)}
                        handleNavigation={() => navigateBack()}
						showNotification={this.props.onAddNotification}
                    />
                </LoadingContent>
            </Page>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.api.loading,
        companies: state.enterprise.companies,
        groups: state.group.list,
        representatives: state.generics.representatives,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAddNotification: (message, level) =>
            dispatch(notificationActions.addNotification(message, level)),
        onGetRepresentatives: () =>
            dispatch(genericsActions.getRepresentatives()),
        onAddUser: data => dispatch(usersActions.addUser(data)),
        onEditUser: (data, id) => dispatch(usersActions.editUser(data, id)),
    };
};

UserRegisterPage.propTypes = {
    onAddNotification: PropTypes.func.isRequired,
    onAddUser: PropTypes.func.isRequired,
    onEditUser: PropTypes.func.isRequired,
    onGetRepresentatives: PropTypes.func.isRequired,
    companies: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.object),
    ]).isRequired,
    groups: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.object),
    ]).isRequired,
    representatives: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.object),
    ]).isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
    }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRegisterPage);
